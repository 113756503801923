@import "modules/variables";
@import "modules/text";
@import "modules/table";
@import "modules/btn";
@import "modules/form";
@import "modules/notification";
@import "modules/spacing";
@import "modules/pagination";

@import "extend";
@import "layout";

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  margin-top: 0;
  margin-bottom: $spacer;
}
html, #root {
  margin: 0;
}

.container {
  width: 80vw;
  margin: 0 auto;
}

.primary {
  @extend %primary;
}

.success {
  @extend %success;
}

.info {
  @extend %info;
}
