%input-style {
  border-color: $border-color;
  border-radius: $border-radius;
  border-width: 1px;
  padding: ($spacer / 2) $spacer;
  margin-right: $spacer;
  margin-bottom: $spacer;

  &.fullwidth {
    width: 100%;
  }
}

input {
  &[type=text],
  &[type=number],
  &[type=tel],
  &[type=email],
  &[type=date] {
    @extend %input-style;
  }
}

select {
  @extend %input-style;
}

.inline {
  display: inline-block;
}

.form-inline {
  display: flex;
  align-items: center;
  padding: $spacer;

  label {
    padding-right: $spacer;
  }

  label,
  input {
    margin-bottom: 0;
  }
}

.form-error {
  color: $danger;
  font-size: $small-font;
  margin-bottom: 0;
}