.notification {
  border: $border;
  border-radius: $border-radius;
  padding: 1em;

  &.error {
    background-color: $danger;
    color: $white;
  }

  &.info {
    background-color: $info;
    color: $white;
  }

  .closeNotification {
    background-color: transparent;
    color: white;
    border: 0;
    float: right;
    font-size: 15px;
    font-weight: 900;
    margin: 0;
  }
}