.pagination {
  margin: 0;

  div, button, select {
    margin-bottom: 0;
    border-color: $primary;
  }

  .disabled {
    color: $grey;
    border-color: $grey;
  }

  .active {
    color: $primary;
    font-weight: 900;
    background-color: $primary;
    border-color: $primary;
    color: $white;
  }
}