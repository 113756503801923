.text-primary {
  color: $primary
}

.text-success {
  color: $success;
}

.text-info {
  color: $info;
}

.text-danger {
  color: $danger;
}
