body {
  height: 100vh;
}

.nav, main {
  height: 100vh;
  margin: 0;
}

.nav {
  width: $side-menu-width;

  border-right: $border;
  display: flex;
  flex-direction: column;
  padding: 10em 2em;
  position: sticky;
  top: 0px;

  a {
    padding: 8px 0;
  }
}

.main {
  width: calc(100% - #{$side-menu-width});
  padding: 1em;
}

.row {
  display: flex;
  flex-wrap: wrap;

  > .col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%;

    $columns: 12;
    $size: (1/$columns);
    @for $i from 0 through $columns {
      &-#{$i} {
        flex-basis: percentage($size * $i);
        max-width: percentage($size * $i);
      }
    }
  }
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.center-x {
  display: flex;
  justify-content: center;
}

.center-y {
  display: flex;
  align-items: center;
}
