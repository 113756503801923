.partners {
  border: 1px solid #000000;

  > div {
    margin-bottom: 0;

    &.header {
      padding: 8px 8px 0;
    }

    &.contents {
      > div {
        padding: 0 8px 8px;
        margin-bottom: 0;

        &:not(.done) {
          background: #efefef;
        }
      }
    }

    .row {
      align-items: center;

      .company-id {
        width: 10%;
      }

      .company-name {
        width: 55%;
      }

      .status {
        width: 10%;
      }

      .created-at {
        width: 10%;
      }

      .plan-name {
        width: 15%;
      }

      .tel {
        width: 15%;
      }

      .name {
        width: 20%;
      }

      .email {
        width: 65%;
      }

      .address {
        width: 100%;
      }
    }

    &.header {
      background: #eeeeee;
    }

    &.contents {
      > div:nth-of-type(n+2) {
        .row:first-of-type {
          border-top: 1px solid #cccccc;
        }
      }
    }
  }
}
