%primary {
  background: $primary;
  border-color: $primary;
  color: #ffffff;
}

%success {
  background: $success;
  border-color: $success;
  color: #000000;
}

%info {
  background: $info;
  border-color: $info;
  color: #ffffff;
}

%danger {
  background: $danger;
  border-color: $danger;
  color: #ffffff;
}
