$primary: #002738;
$success: #f0d06e;
$info: #52c179;
$danger: #d36262;
$grey: #cccccc;
$light-grey: #eeeeee;
$white: #ffffff;

$border-color: $grey;
$border-radius: 0.2rem;
$border-width: 1px;
$border: $border-width solid $border-color;
$spacer: 8px;

$small-font: 0.75rem;

$side-menu-width: 175px;

:root {
  --light-grey: #{$light-grey};
}
