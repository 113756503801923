a {
  color: #8d6a00;
}

a,
button,
input[type="submit"],
input[type="button"] {
  &.btn {
    background: #ffffff;
    color: #000000;
    text-decoration: none;
    font-weight: 500;
    border: 1px solid $border-color;
    border-radius: $border-radius;
    padding: ($spacer / 2) ($spacer * 2);

    &:hover {
      opacity: 0.5;
    }

    &-primary {
      @extend %primary;
    }
    &-success {
      @extend %success;
    }
    &-info {
      @extend %info;
    }
    &-danger {
      @extend %danger;
    }

    &.large {
      padding: 1em;
    }

    &.fullwidth {
      width: 100%;
      text-align: center;
    }
  }
}
