table {
  border-collapse: collapse;

  &,
  tr,
  th,
  td {
    border-color: $border-color;
    border-style: solid;
    border-width: 0;
  }

  th {
    text-align: center;
  }

  th,
  td {
    padding: $spacer;
  }

  &.table-border {
    border-top-width: 1px;
    border-left-width: 1px;
    border-color: $border-color;

    > thead,
    > tbody {
      > tr {
        >th,
        >td {
          border-right-width: 1px;
          border-bottom-width: 1px;
          border-color: $border-color;
        }
      }
    }
  }

  &.center-table td {
    text-align: center;
  }

  &.fullwidth {
    width: 100%;
  }

  &.table-xborder {
    outline: $border;
    outline-offset: $spacer;
    margin: $spacer;

    tr {
      border-bottom-width: 1px;
    }

    thead {
      background-color: $light-grey;
    }
  }
}
