@for $i from 0 through 10 {
  .m-#{$i} { margin: $spacer * $i; }
  .mt-#{$i} { margin-top: $spacer * $i; }
  .mb-#{$i} { margin-bottom: $spacer * $i; }
  .ml-#{$i} { margin-left: $spacer * $i; }
  .mr-#{$i} { margin-right: $spacer * $i; }
  .mx-#{$i} { margin-right: $spacer * $i; margin-left: $spacer * $i; }
  .my-#{$i} { margin-top: $spacer * $i; margin-bottom: $spacer * $i; }

  .p-#{$i} { padding: $spacer * $i; }
  .pt-#{$i} { padding-top: $spacer * $i; }
  .pb-#{$i} { padding-bottom: $spacer * $i; }
  .pl-#{$i} { padding-left: $spacer * $i; }
  .pr-#{$i} { padding-right: $spacer * $i; }
  .px-#{$i} { padding-right: $spacer * $i; padding-left: $spacer * $i; }
  .py-#{$i} { padding-top: $spacer * $i; padding-bottom: $spacer * $i; }
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-botom: auto;
}

.d-flex {
  display: flex;
}